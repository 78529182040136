<i18n>
{
  "en": {
    "title": "Deals",
    "search": "Search for owner, company or contact...",
    "create": "New deal",
    "filter": "Filter",
    "export-results": "Export results",
    "edit-filtered": "Edit filtered data",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "reload": "Reload",
    "export": "Export",
    "export-all": "Export all",
    "export-messages": "Export messages logs",
    "import": "Import",
    "not-found": "Leads not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one",
    "column-1": "Name",
    "column-2": "Related contacts",
    "column-3": "Owner",
    "column-4": "Created at",
    "column-5": "Updated at",
    "not-assigned": "Not assigned"
  },
  "pt-BR": {
    "title": "Cards",
    "search": "Pesquise pelo responsável, empresa, contato...",
    "create": "Novo card",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "edit-filtered": "Editar registros filtrados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "reload": "Recarregar",
    "export": "Exportar",
    "export-all": "Exportar tudo",
    "export-messages": "Exportar logs de mensagens",
    "import": "Importar",
    "not-found": "Nenhumo card encontrada",
    "not-found-description": "Verifique sua pesquisa novamente ou clique no botão abaixo para criar",
    "column-1": "Nome",
    "column-2": "Contatos relacionados",
    "column-3": "Responsável",
    "column-4": "Data de cadastro",
    "column-5": "Última atualização",
    "not-assigned": "Nenhum"
  }
}
</i18n>

<template>
  <div id="deals">
    <nav-top :title="$t('title')">
      <template #top-content>
        <we-input
          type="search"
          v-model="search"
          @input="searchDeals"
          :inputLabel="$t('search')"
          cy="search-in-deals"
        ></we-input>
        <we-button
          class="green"
          :text="$t('create')"
          icon="note-sticky"
          @click="newDeal"
          cy="home-create-deal-in-deals"
        />
      </template>
    </nav-top>
    <board-list class="deals">
      <router-view />
      <template #filters>
        <we-pagination :pages="dealsPages" @change="selectPage($event)" />
        <we-button
          :class="filterActive ? 'primary' : 'disabled'"
          :text="$t('filter')"
          icon="sliders-h"
          @click="$router.push({ name: 'filterDeal' })"
          cy="filter-in-deals"
        />
        <we-balloon v-if="filterActive">
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export-results')"
              color="icon-primary"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFilteredFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text">
                {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          v-if="filterActive"
          :icon="['fas', 'edit']"
          :name="$t('edit-filtered')"
          @click="massUpdateOpened = true"
          cy="update-filtered-deals"
        />
        <mass-update
          :open="massUpdateOpened"
          @close="massUpdateOpened = false"
          @confirm="doRequests"
          :total-records="dealsPages.total"
        />
        <we-icon-button
          v-if="filterActive"
          :icon="['far', 'trash-alt']"
          :name="$t('delete-filtered')"
          color="icon-red"
          @click="confirmDeleteOpened = true"
          cy="delete-filtered-deals"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          :total-records="dealsPages.total"
          @confirm="deleteFilteredData()"
          @close="confirmDeleteOpened = false"
        />
      </template>
      <template #actions>
        <we-icon-button
          icon="sync-alt"
          @click="doRequests(true)"
          :name="$t('reload')"
          cy="reload-deals"
        />
        <we-balloon>
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export')"
              cy="export-deals-trigger"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFile">
              <font-awesome-icon icon="file-csv" />
              <div class="text" cy="export-deals">
                {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
            <div class="balloon-item" @click="exportMessages">
              <font-awesome-icon icon="comments" />
              <div class="text" cy="export-pipelines">
                {{ $t("export-messages") }}
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          icon="file-upload"
          @click="openImport"
          :name="$t('import')"
          cy="import-deals"
        />
      </template>
      <template #list>
        <we-loading-overflow :loading="loadingDeals" />
        <div class="labels">
          <div
            class="label"
            :class="[
              elem.orderBy === dealsSort.orderBy ? dealsSort.direction : '',
              !elem.orderBy ? 'disabled' : ''
            ]"
            v-for="elem in sortElements"
            :key="elem.orderBy"
            @click="elem.orderBy && orderBy(elem)"
          >
            <we-text-tooltip class="name" :text="elem.name" lines="1" />
            <div class="icon">
              <font-awesome-icon icon="sort-up" />
              <font-awesome-icon icon="sort-down" />
            </div>
          </div>
        </div>
        <div class="items">
          <we-not-found v-if="deals.length === 0">
            <template #title> {{ $t("not-found") }} </template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #actions>
              <we-button
                class="green"
                :text="$t('create')"
                icon="note-sticky"
                @click="newDeal"
              />
            </template>
          </we-not-found>
          <div
            class="card-list"
            v-for="(deal, i) in deals"
            :key="i"
            @click="openDeal(deal.id)"
          >
            <div class="column">
              <we-text-tooltip class="name" :text="deal.name" lines="1" />
            </div>
            <div class="column">
              <we-text-tooltip
                class="name"
                :text="firstContactName(deal)"
                :tooltipText="allContactsNames(deal)"
                lines="1"
              />
              <div class="related-contacts">
                {{ deal.contacts ? deal.contacts.length : 0 | moreThanOne }}
              </div>
            </div>
            <div class="column user">
              <template v-if="deal.user">
                <we-rounded-picture
                  :picture="deal.user.picture"
                  :name="deal.user.first_name"
                  :text="
                    $options.filters.firstLetters(
                      `${deal.user.first_name} ${deal.user.last_name}`
                    )
                  "
                  size="26"
                />
                <we-text-tooltip
                  class="name"
                  :text="`${deal.user.first_name} ${deal.user.last_name}`"
                  lines="1"
                />
              </template>
              <template v-else>
                <we-text-tooltip
                  class="name"
                  :text="$t('not-assigned')"
                  lines="1"
                />
              </template>
            </div>
            <div class="column">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(deal.created_at)"
                lines="1"
              />
            </div>
            <div class="column">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(deal.updated_at)"
                lines="1"
              />
            </div>
          </div>
        </div>
      </template>
    </board-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      massUpdateOpened: false,
      confirmDeleteOpened: false,
      createValid: false,
      loadingBtn: false,
      loadingDeals: true,
      initialPage: 1,
      search: "",
      sortElements: [
        {
          name: this.$t("column-1"),
          orderBy: "name",
          direction: "desc"
        },
        {
          name: this.$t("column-2")
        },
        {
          name: this.$t("column-3")
        },
        {
          name: this.$t("column-4"),
          orderBy: "created_at",
          direction: "desc"
        },
        {
          name: this.$t("column-5"),
          orderBy: "updated_at",
          direction: "desc"
        }
      ]
    };
  },
  computed: {
    deals() {
      return this.$store.getters.getDeals;
    },
    dealsPages() {
      return this.$store.getters.getDealsPages;
    },
    dealsSort() {
      return this.$store.getters.getDealsSort;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "dealsRequest",
      "createDealRequest",
      "searchDealsRequest",
      "filterDealsRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "deleteFilteredRecords",
      "exportLogMessages"
    ]),
    async deleteFilteredData() {
      this.loadingDeals = true;
      await this.deleteFilteredRecords();
      await this.doRequests();
    },
    exportFile() {
      const payload = {
        entity: "deals"
      };

      this.exportRequest(payload);
    },
    exportMessages() {
      this.exportLogMessages();
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("deals-filtered");
      }
    },
    openImport() {
      this.$router.push({ name: "importDeals" });
    },
    searchDeals(val) {
      this.loadingDeals = true;
      this.$store.commit("setFilterActive", false);
      let orderBy = {};
      if (this.dealsSort.orderBy) {
        orderBy = {
          orderBy: this.dealsSort.orderBy,
          direction: this.dealsSort.direction
        };
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (val?.length > 0) {
          await this.searchDealsRequest({
            value: this.search,
            page: 1,
            ...orderBy,
            paginate: true
          });
        } else {
          await this.doRequests();
        }
        this.loadingDeals = false;
      }, 600);
    },
    dealContacts(deal) {
      return deal.contacts ? deal.contacts?.slice(0, 1) : [];
    },
    firstContactName(deal) {
      if (!deal.contacts) return "";
      if (deal?.contacts?.length <= 0) return ``;

      return `${deal.contacts[0]?.first_name} ${deal.contacts[0]?.last_name}`;
    },
    allContactsNames(deal) {
      let names = "";

      if (deal.contacts) {
        names = deal.contacts
          .map(e => `${e.first_name} ${e.last_name}`)
          .join(", ");
      }

      return names;
    },
    newDeal() {
      this.$router.push({ name: "createDealInDeals" });
    },
    openDeal(id) {
      this.$router.push({ name: "deal", params: { dealId: id } });
    },
    async orderBy(elem) {
      this.loadingDeals = true;

      if (elem.direction === "desc") {
        elem.direction = "asc";
      } else {
        elem.direction = "desc";
      }

      this.$store.commit("setDealsSort", elem);

      let orderBy = {};
      if (elem.orderBy) {
        orderBy = {
          orderBy: elem.orderBy,
          direction: elem.direction
        };
      }

      if (this.filterActive) {
        await this.filterDealsRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.dealsPages.current_page,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchDealsRequest({
          value: this.search,
          page: this.dealsPages.current_page,
          paginate: true,
          ...orderBy
        });
      } else {
        await this.dealsRequest({
          page: this.dealsPages.current_page,
          updateLocal: false,
          ...orderBy
        });
      }
      this.loadingDeals = false;
    },
    async doRequests(reset) {
      this.loadingDeals = true;
      if (reset) {
        this.dealsPages.current_page = this.initialPage;
        this.$store.commit("setFilterActive", false);
        this.$store.commit("setDealsSort", {
          orderBy: "",
          direction: "",
          name: ""
        });
      }

      if (this.filterActive) {
        await this.filterDealsRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.dealsPages.current_page
        });
      } else if (this.search.length > 0) {
        await this.searchDealsRequest({
          value: this.search,
          page: this.dealsPages.current_page,
          paginate: true
        });
      } else {
        await this.dealsRequest({ page: this.dealsPages.current_page });
      }

      this.loadingDeals = false;
    },
    async selectPage(val) {
      this.loadingDeals = true;
      let orderBy = {};
      if (this.dealsSort.orderBy) {
        orderBy = {
          orderBy: this.dealsSort.orderBy,
          direction: this.dealsSort.direction
        };
      }

      if (this.filterActive) {
        await this.filterDealsRequest({
          filters: this.exportParams?.payload?.filters,
          page: val,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchDealsRequest({
          value: this.search,
          page: val,
          ...orderBy
        });
      } else {
        await this.dealsRequest({
          page: val,
          ...orderBy,
          updateLocal: false
        });
      }

      this.loadingDeals = false;
    }
  },
  async mounted() {
    await this.doRequests(true);
    this.$store.commit("resetFilters");
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === "filterDeal") {
      this.search = "";
    }
    if (from.name === "importDeals") {
      this.doRequests();
    }
    if (to.query.reload) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#deals {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
